<template>
  <v-container fluid class="pa-0 ma-0">
    <v-container fluid class="content">
      <v-row justify="space-between" align="center" class="pa-0 ma-0 mb-2">
        <strong>Ficha de Gastos</strong>
        <div style="display: flex; flex-direction: row; align-items: center;">

          <v-card elevation="0" v-if="clean" class="pr-2">
            <span class="mr-2">¿Seguro?</span>
            <v-btn
              color="green"
              class="primary-text--text mr-2"
              elevation="0"
              small
              @click="resetItem()"
            >
              <small>si</small>
            </v-btn>
            <v-btn
              color="red"
              class="primary-text--text mr-2"
              elevation="0"
              small
              @click="() => { clean = false }"
            >
              <small>no</small>
            </v-btn>
          </v-card>
          <v-btn
            :color="items.length == 0 ? 'green' : 'secondary-color'"
            class="primary-text--text"
            elevation="0"
            @click="addItem()"
          >
            <small><v-icon left small>mdi-plus</v-icon> agregar gasto</small>
          </v-btn>
        </div>
      </v-row>
      <v-divider></v-divider>
      <v-card
        elevation="0"
      >
        <v-col cols="12" class="w-full pa-0 ma-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="4">
              <strong>Ítem de gasto</strong>
            </v-col>
            <v-col cols="4">
              <strong>Descripción del gasto</strong>
            </v-col>
            <v-col cols="3">
              <strong>Monto</strong>
            </v-col>
            <v-col cols="1">
              <strong></strong>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" v-if="items.length == 0" class="ma-0 pa-0">
            <v-col cols="3">
              <span v-if="!loading">Sin Gastos</span>
              <span v-else>Buscando Gastos ...</span>
            </v-col>
          </v-row>
          <v-row align="center" v-for="(item, itemIndex) in items" :key="itemIndex" class="ma-0 pa-0">
            <v-col cols="4">
              <select-component
                placeholder="Ítem"
                :must="['required']"
                :items="options"
                :hide_details="true"
                icon="mdi-arrow-bottom-right-thin-circle-outline"
                :default="items[itemIndex].nombre"
                @value="(value) => {items[itemIndex].nombre = value}"
                @blur="editExpense(item)"
              />
            </v-col>
            <v-col cols="4">
              <v-textarea
                filled
                name="input-7-4"
                label="Descripción"
                color="primary-color"
                no-resize
                v-model="items[itemIndex].descripcion"
                counter
                :maxlength="300"
                :rules="[value => value.length <= 300 || 'Maximo 300 caracteres', value => !!value || 'Requerido.']"
                @blur="editExpense(item)"
              ></v-textarea>
            </v-col>
            <v-col cols="3">
              <input-text
                :placeholder="formatMoney(items[itemIndex].monto)"
                :must="['required']"
                :type="'number'"
                @text="(value) => { items[itemIndex].monto = value }"
                :value="items[itemIndex].monto"
                :hide_details="true"
                @blur="editExpense(item)"
              />
            </v-col>
            <v-col cols="1">
              <v-btn
                color="red"
                class="primary-text--text"
                elevation="0"
                small
                @click="removeItem(itemIndex)"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-container>
    <v-container class="content-footer pa-0 pt-3 mb-16">
      <v-row justify="space-between" align="center" class="ma-0 pa-0">
        <v-btn
            color="primary-color"
            class="primary-text--text"
            elevation="0"
            :to="'/ficha-plan-operativo'"
            small> <v-icon left small>mdi-arrow-left</v-icon> Volver
        </v-btn>
        <div>
          <small class="mr-4"><b>{{ items.length }} gasto{{ items.length == 1 ? '' : 's' }}</b></small>
        </div>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import InputText from '@/components/form/InputText.vue';
import Select from '@/components/form/Select.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'expense-tab',
  components: {
    'input-text': InputText,
    'select-component': Select,
  },
  data() {
    return {
      sent: false,
      loading: true,
      clean: false,
      toDelete: [],
      items: [
        // {
        //   nombre: '',
        //   descripcion: '',
        //   monto: '',
        // }
      ],
      options: [
        'Honorarios',
        // 'Becas estudiantiles',
        'Becas estudiantiles (Beca de trabajo, Beca de movilidad o Beca de ayuda económica)',
        'Bienes y equipos',
        'Materiales e Insumos de laboratorios',
        'Mobiliario',
        // 'Becas de movilidad',
        // 'Catering',
        'Servicio de Catering o Coffee Break',
        'Visita académica',
        'Estadía académica',
        'Conferencista',
        // 'Arriendo Espacios',
        'Arriendo',
        // 'Software',
        // 'Servicios Difusión',
        'Servicios',
        'Servicios y productos de difusión',
        // 'Merchandising',
        // 'Estadías académicos',
        // 'Equipos Computacionales',
        'Importaciones o Compras Internacionales',
        'Otros'
      ]
    }
  },
  created() {
    // if (this.info && !this.info.leido) {
    //   this.$router.replace('/home');
    // }
    // if it does not exists go home
    this.getProject(this.id);
    this.getExpense(this.id);
  },
  methods: {
    ...mapActions('main', [
      'getProyectoById',
      'getItemGastoByProyecto',
      'createItemGasto',
      'updateItemGasto',
      'deleteItemGasto'
    ]),
    formatMoney(value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'CLP',
        minimumFractionDigits: 0
      });

      return formatter.format(value);
    },
    getProject(id) {
      this.getProyectoById(id)
        .then(response => {
          this.project = response.data.response;
        })
        .catch(err => {
          console.log(err);
          this.$router.replace('/home');
        });
    },
    getExpense(id) {
      this.getItemGastoByProyecto(id)
        .then(response => {
          response.data.response.forEach(expense => {
            this.items.push({
              id: expense.id,
              nombre: expense.nombreItem,
              descripcion: expense.descripcionItem,
              monto: expense.montoItem,
            });
          });
          this.loading = false;
        })
        .catch(err => console.log(err));
    },
    activeClean() {
      this.clean = true;
      setTimeout(() => {
        this.clean = false;
      }, 10000);
    },
    resetItem() {
      this.items.forEach(item => {
        if (item.id != null) {
          this.toDelete.push(item.id);
        }
      });
      this.items = [];
      this.clean = false;
    },
    addItem() {
      this.items.push(
        {
          id: null,
          nombre: '',
          descripcion: '',
          monto: 0,
        }
      );
      this.save();
    },
    removeItem(itemIndex) {
      if (this.items[itemIndex].id != null) {
        this.deleteExpense(this.items[itemIndex].id)
      }
      this.items = this.items.filter((e,i) => {
        e;
        return i != itemIndex;
      });
    },
    save() {
      this.sent = true;
      this.items.forEach((item, index) => {
        if (item.id == null) {
          this.createExpense(item,index);
        } else {
          this.editExpense(item);
        }
      });
      this.toDelete.forEach(id => {
        this.deleteExpense(id);
      });
      this.sent = false;
    },
    createExpense(expense,index) {
      expense;
      index;
      this.createItemGasto({
        id: this.id,
        itemGasto: {
          nombre: expense.nombre,
          descripcion: expense.descripcion,
          monto: expense.monto
        }
      })
        .then(response => {
          this.items[index].id = response.data.response.id;
        })
        .catch(err => console.log(err));
    },
    editExpense(expense) {
      this.updateItemGasto({
        id: expense.id,
        itemGasto: {
          nombre: expense.nombre,
          descripcion: expense.descripcion,
          monto: expense.monto
        }
      })
        .then(response => console.log(response.data.response))
        .catch(err => console.log(err));
    },
    deleteExpense(id) {
      this.deleteItemGasto(id)
        .then(response => console.log(response.data.response))
        .catch(err => console.log(err));
    }
  },
  watch: {
    id() {
      this.loading = true;
      this.getProject(this.id);
      this.getExpense(this.id);
    },
  },
  computed: {
    ...mapGetters({
      info: ['authentication/info']
    }),
    id() {
      return this.$route.params.id;
    },
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return (
        this.$vuetify.breakpoint.md ||
        this.$vuetify.breakpoint.lg ||
        this.$vuetify.breakpoint.xl
      );
    },
  },
}
</script>

<style scoped>
.content {
  background-color: white;
  position: relative;
  width: 90%;
  max-width: 1500px;
  margin-top: 100px;
  border-radius: 10px;
  border: 1px solid #dedede;
  -webkit-box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
  -moz-box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
  box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
}
.content-footer {
  position: relative;
  width: 90%;
  max-width: 1500px;
  border-radius: 10px;
  -webkit-box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
  -moz-box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
  box-shadow: 1px 1px 190px 0px rgba(222,222,222,1);
}
.dropzone-box {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.square-background {
 width: 100%;
 margin-left: auto;
 margin-right: auto;
 display: flex;
 justify-content: center;
 align-items: center;
 font-size: 12px;
}
.web-grid div::before,
.web-grid-profile div::before {
  content: '';
  padding-bottom: 100%;
  display: inline-block;
  vertical-align: top;
}
.border {
  border: 1px solid #dedede;
  width: 100%;
}
</style>